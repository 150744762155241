<template>
  <div
    class="fixed top-0 left-0 z-10 flex items-center justify-center w-screen h-screen bg-black bg-opacity-50 p-12"
  >
    <div class="flex flex-col items-center p-12 rounded-lg w-2/5 h-96 bg-primary-light z-50">
      <div
        v-if="reportType == 'full_cognitive_report'"
      >
        <template v-if="!outOfReports">
          <h4 class="text-center leading-snug">
            Do you want to open the full report for candidate {{ candidate.alt_id }}?
          </h4>
          <span class="text-sm"> {{ subscription.termRemaningReports }} reports remaning</span>
        </template>
        <template v-else-if="!subscription.inTrial">
          <h5 class="text-center leading-snug">
            Do you want to buy reports for candidate {{ candidate.alt_id }} ({{ subscription.additionalReportsPrice }} {{ subscription.plan.split('-')[1] }}) ?
          </h5>
          <span class="text-sm"> Upgrade your subscription to get access to more reports per month. </span>
        </template>
        <template v-else>
          <h5 class="text-center leading-snug">
            It appears you've reached the limit of reports available in your free trial. To unlock additional reports, please select a suitable plan.
          </h5>
        </template>
      </div>
      <div
        v-else
      >
        <template v-if="!outOfReports">
          <h4 class="text-center leading-snug">
            Do you want to open the {{ label }} for candidate {{ candidate.alt_id }}?
          </h4>
          <span class="text-sm"> {{ subscription.termRemaningReports }} reports remaning</span>
        </template>
        <template v-else-if="!subscription.inTrial">
          <h5 class="text-center leading-snug">
            Do you want to buy reports for candidate {{ candidate.alt_id }} ({{ subscription.additionalReportsPrice }} {{ subscription.plan.split('-')[1] }})?
          </h5>
          <span class="text-sm"> Upgrade your subscription to get access to more reports per month. </span>
        </template>
        <template v-else>
          <h5 class="text-center leading-snug">
            It appears you've reached the limit of reports available in your free trial. To unlock additional reports, please select a suitable plan.
          </h5>
        </template>
      </div>

      <div class="flex justify-evenly mt-12">
        <button
          @click="$emit('close')"
          class="btn-secondary mr-10"
        >
          Cancel
        </button>
        <template v-if="reportType == 'full_cognitive_report'">
          <router-link
            v-if="!outOfReports"
            :to="{ name: 'AppReport', params: { userId: candidate.user_id }, query: { comparisonPage: true, campaignId: campaignId, isTeam: true} }"
            class="btn-primary"
          >
            Yes
          </router-link>
          <router-link
            v-else-if="!subscription.inTrial"
            :to="{ name: 'AppReport', params: { userId: candidate.user_id }, query: { comparisonPage: true, campaignId: campaignId, isTeam: true} }"
            class="btn-primary"
          >
            Yes
          </router-link>
          <router-link
            v-else
            :to="{ name: 'Checkout', query: { fromTrial: true} }"
            class="btn-primary"
          >
            Select plan
          </router-link>
        </template>
        <template v-else-if="reportType == 'executive_summary_report'">
          <router-link
            v-if="!outOfReports"
            :to="{ name: 'ExecutiveReport', params: { userId: candidate.user_id }, query: { campaignId: campaignId, isTeam: true } }"
            class="btn-primary"
          >
            Yes
          </router-link>
          <router-link
            v-else-if="!subscription.inTrial"
            :to="{ name: 'ExecutiveReport', params: { userId: candidate.user_id }, query: { campaignId: campaignId, isTeam: true } }"
            class="btn-primary"
          >
            Yes
          </router-link>
        </template>
        <template v-else-if="reportType == 'personality_report'">
          <div
            v-if="!outOfReports"
            class="btn-primary"
            @click="$emit('open-report')"
          >
            Yes
          </div>
          <div
            v-else-if="!subscription.inTrial"
            class="btn-primary"
          >
            Yes
          </div>
          <router-link
            v-else
            :to="{ name: 'Checkout', query: { fromTrial: true} }"
            class="btn-primary"
          >
            Select plan
          </router-link>
        </template>
        <template v-else>
          <router-link
            v-if="!outOfReports"
            :to="{ name: 'SubReport', params: { userId: candidate.user_id }, query: { comparisonPage: true, campaignId: campaignId, reportType: reportType, showInfo: showInfo, isTeam: true } }"
            class="btn-primary"
          >
            Yes
          </router-link>
          <router-link
            v-else-if="!subscription.inTrial"
            :to="{ name: 'SubReport', params: { userId: candidate.user_id }, query: { comparisonPage: true, campaignId: campaignId, reportType: reportType, showInfo: showInfo, isTeam: true } }"
            class="btn-primary"
          >
            Yes
          </router-link>
          <router-link
            v-else
            :to="{ name: 'Checkout', query: { fromTrial: true} }"
            class="btn-primary"
          >
            Select plan
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  props: {
    candidate: {
      type: Object,
      required: true
    },
    campaignId: {
      type: String,
      required: true
    },
    reportType: {
      type: String,
      required: true
    },
    showInfo: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    }

  },
  computed: {
    ...mapGetters({ subscription: 'USER/getSubscription' }),
    outOfReports () {
      return this.subscription.termRemaningReports <= 0
    }
  }

}
</script>

<style>

</style>
