<template>
  <tr
    v-if="hasDoneReport"
    class="border-2 py-4 my-2"
  >
    <td class="py-6">
      <div class="flex items-center">
        <div class="flex items-center">
          <button
            @click="handleFavorite"
            class="mr-4 cursor-pointer"
          >
            <svg
              class="w-10 h-10 text-green-900 star"
              :class="{'star-solid': candidate.favorite}"
              viewBox="0 0 24 24"
              stroke="#46634F"
              stroke-width="1.4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
              />
            </svg>
          </button>
          <div class="flex flex-col mt-1 py-4">
            <span
              :class="{ 'text-xs': showCandidateInfo }"

              class="text-base"
              v-if="!showCandidateInfo"
            >Candidate {{ candidate.alt_id }}

            </span>
            <span
              v-if="showCandidateInfo && candidate.name"
              class="text-md black-cam3 text-base font-medium"
              style="width: fit-content"
              v-tippy="{arrow : true, arrowType : 'round', animation : 'fade', size: 'large', allowHTML: true, placement : 'top'}"
              :content="'Age: '+ candidate.current_age + '<br>' + 'Gender: ' + candidate.gender"
            >{{ candidate.name }}
            </span>
            <div class="flex flex-row items-center">
              <span
                v-if="showCandidateInfo && candidate.email"
                class="font-normal text-sm black-cam4 my-2"
              >{{
                candidate.email
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </td>
    <td />
    <td class="py-6 text-xs">
      <div class="flex items-center text-left">
        {{ `${candidate.teamRole}, ${candidate.position}, ${candidate.department}, ${candidate.company}, ${candidate.division}` }}
      </div>
    </td>
    <td class="py-6">
      <div
        v-if="candidate.tested"
        class="flex items-center justify-center"
      >
        <svg
          class="w-10 h-10"
          viewBox="0 0 344 344"
          v-tippy="{arrow : true, arrowType : 'round', animation : 'fade', size: 'large', allowHTML: true, placement : 'top'}"
          :content="testStatusTooltip()"
        >
          <g>
            <path
              :style="{ opacity: candidate.tested[3] ? '1' : '0.15' }"
              stroke="white"
              stroke-width="3"
              d="M170.705+170.705L1.00001+171.055C0.968836+77.3966+76.6971+1.35527+170.355+1.00001L170.705+170.705Z"
              :fill="$options.gameColors.strategicThinkingColor"
            />
            <path
              :style="{ opacity: candidate.tested[0] ? '1' : '0.15' }"
              stroke="white"
              stroke-width="3"
              d="M170.705+170.705L170.355+1.00001C264.014+0.968825+340.055+76.6971+340.411+170.355L170.705+170.705Z"
              :fill="$options.gameColors.attentionColor"
            />
            <path
              :style="{ opacity: candidate.tested[1] ? '1' : '0.15' }"
              stroke="white"
              stroke-width="3"
              d="M170.705+170.705L171.055+340.411C264.713+340.055+340.442+264.014+340.411+170.355L170.705+170.705Z"
              :fill="$options.gameColors.workingMemoryColor"
            />
            <path
              :style="{ opacity: candidate.tested[2] ? '1' : '0.15' }"
              stroke="white"
              stroke-width="3"
              d="M170.705+170.705L1.00001+171.055C1.3553+264.713+77.3966+340.442+171.055+340.411L170.705+170.705Z"
              :fill="$options.gameColors.impulseControlColor"
            />
          </g>
        </svg>
      </div>
    </td>
    <td
      class="py-6 flex items-center justify-center text-center"
      :class="{ 'font-extrabold': sorting.variable === 'state' }"
    >
      <span
        v-if="!showCandidateInfo"
        class="text-2xl line-height-3rem mb-3"
        :style="'color: ' + content.colorTranslations[stateColorChange(candidate.state, candidate.testIssue)]"
      >⬤</span>
      <span
        v-if="showCandidateInfo"
        class="text-2xl line-height-7rem mb-4"
        :style="'color: ' + content.colorTranslations[stateColorChange(candidate.state, candidate.testIssue)]"
      >⬤</span>
      <svg
        v-if="candidate.testIssue !== null"
        content="User experienced some issues during testing."
        v-tippy="{arrow : true, arrowType : 'round', animation : 'fade', size: 'large'}"
        width="16"
        height="16"
        viewBox="0 0 16 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="mx-3"
      >
        <path
          d="M1 10H15L10.5 5.5L15 1H1V17"
          fill="#CD2E00"
        />
        <path
          d="M1 10H15L10.5 5.5L15 1H1V17"
          stroke="#CD2E00"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </td>
    <td
      class="text-base py-6 table-background-color"
      :class="{ 'font-extrabold': sorting.variable === 'strength' }"
    >
      <div class="flex items-center mx-3">
        <capacity-icon
          class="mr-4"
          :capacity-level="candidate.capacity_level"
        />
        <span class="text-base">{{ getStrengthLabel(candidate.strength) }} </span>
      </div>
    </td>
    <td

      :class="{ 'font-extrabold': sorting.variable === 'total_capacity_score' }"
      class="black-cam3 text-base pt-11 pb-10 table-background-color text-center"
    >
      {{ candidate.total_capacity_score.toFixed(1) }}
    </td>

    <td
      :class="{ 'font-extrabold': sorting.variable === 'awareness' }"
      class="text-base py-6 table-background-color"
    >
      {{ content.awarenessTranslations[candidate.awareness] }}
    </td>

    <td
      :class="{ 'font-extrabold': sorting.variable === 'variation' }"
      class="table-background-color"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-full"
        fill="none"
        viewBox="0 0 50 24"
        stroke="currentColor"
        stroke-width="2"
        :transform="`rotate(${180 - (candidate.variation - 1) * 45})`"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M5 10l7-7m0 0l7 7m-7-7v18"
        />
      </svg>
    </td>

    <td
      v-if="candidate.matching != -1 && candidate.tested != null"
      class="py-6 table-background-color"
    >
      <MatchingBox
        :matching="candidate.matching"
        :applicable="hasFullReport"
      />
    </td>
    <td class="py-6 table-background-color">
      <div class="mr-1 dropdown dropdown-left">
        <label
          tabindex="0"
          class="cursor-pointer"
        > <button class="btn-primary-dropdown flex justify-center items-center">
          Reports<svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-6 h-6 ml-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button></label>
        <ul
          tabindex="0"
          class="p-2 shadow-xl dropdown-content menu rounded-box bg-white"
          style="z-index: 10 !important;"
        >
          <li v-show="hasFullReport">
            <a
              @click="handleOpenReport()"
              class="text-base"
            >
              <svg
                class="w-10 h-10 mr-5"
                viewBox="0 0 344 344"
                v-tippy="{arrow : true, arrowType : 'round', animation : 'fade', size: 'large', allowHTML: true, placement : 'top'}"
                :content="testStatusTooltip()"
              >
                <g>
                  <path
                    stroke="white"
                    stroke-width="3"
                    d="M170.705+170.705L1.00001+171.055C0.968836+77.3966+76.6971+1.35527+170.355+1.00001L170.705+170.705Z"
                    :fill="$options.gameColors.strategicThinkingColor"
                  />
                  <path
                    stroke="white"
                    stroke-width="3"
                    d="M170.705+170.705L170.355+1.00001C264.014+0.968825+340.055+76.6971+340.411+170.355L170.705+170.705Z"
                    :fill="$options.gameColors.attentionColor"
                  />
                  <path
                    stroke="white"
                    stroke-width="3"
                    d="M170.705+170.705L171.055+340.411C264.713+340.055+340.442+264.014+340.411+170.355L170.705+170.705Z"
                    :fill="$options.gameColors.workingMemoryColor"
                  />
                  <path
                    stroke="white"
                    stroke-width="3"
                    d="M170.705+170.705L1.00001+171.055C1.3553+264.713+77.3966+340.442+171.055+340.411L170.705+170.705Z"
                    :fill="$options.gameColors.impulseControlColor"
                  />
                </g>
              </svg>
              Full cognitive report <span
                v-if="openedFull"
                class="test-white text-base"
              >&nbsp;(opened)</span>
            </a>
          </li>
          <li v-show="hasFullReport">
            <a
              @click="handleOpenExecutive()"
              class="text-base"
            >
              <svg
                class="w-10 h-10 mr-5"
                viewBox="0 0 344 344"
                v-tippy="{arrow : true, arrowType : 'round', animation : 'fade', size: 'large', allowHTML: true, placement : 'top'}"
                :content="testStatusTooltip()"
              >
                <g>
                  <path
                    stroke="white"
                    stroke-width="3"
                    d="M170.705+170.705L1.00001+171.055C0.968836+77.3966+76.6971+1.35527+170.355+1.00001L170.705+170.705Z"
                    :fill="$options.gameColors.strategicThinkingColor"
                  />
                  <path
                    stroke="white"
                    stroke-width="3"
                    d="M170.705+170.705L170.355+1.00001C264.014+0.968825+340.055+76.6971+340.411+170.355L170.705+170.705Z"
                    :fill="$options.gameColors.attentionColor"
                  />
                  <path
                    stroke="white"
                    stroke-width="3"
                    d="M170.705+170.705L171.055+340.411C264.713+340.055+340.442+264.014+340.411+170.355L170.705+170.705Z"
                    :fill="$options.gameColors.workingMemoryColor"
                  />
                  <path
                    stroke="white"
                    stroke-width="3"
                    d="M170.705+170.705L1.00001+171.055C1.3553+264.713+77.3966+340.442+171.055+340.411L170.705+170.705Z"
                    :fill="$options.gameColors.impulseControlColor"
                  />
                </g>
              </svg>
              Executive summary report <span
                v-if="openedExecutive"
                class="test-white text-base"
              >&nbsp;(opened)</span>
            </a>
          </li>
          <li v-show="hasTestedReports && !hasFullReport">
            <!--If user has full report, we don't show subreports  -->
            <a
              v-for="(report, index) in testedReports"
              :key="index"
              @click="handleOpenSubReport(report)"
              class="text-base"
            >
              <svg
                class="w-10 h-10 mr-5"
                viewBox="0 0 344 344"
                v-tippy="{arrow : true, arrowType : 'round', animation : 'fade', size: 'large', allowHTML: true, placement : 'top'}"
                :content="testStatusTooltip()"
              >
                <g>
                  <path
                    :style="{ opacity: report.label === 'Envision report' ? '1' : '0.15' }"
                    stroke="white"
                    stroke-width="3"
                    d="M170.705+170.705L1.00001+171.055C0.968836+77.3966+76.6971+1.35527+170.355+1.00001L170.705+170.705Z"
                    :fill="$options.gameColors.strategicThinkingColor"
                  />
                  <path
                    :style="{ opacity: report.label === 'Gather & process report' ? '1' : '0.15' }"
                    stroke="white"
                    stroke-width="3"
                    d="M170.705+170.705L170.355+1.00001C264.014+0.968825+340.055+76.6971+340.411+170.355L170.705+170.705Z"
                    :fill="$options.gameColors.attentionColor"
                  />
                  <path
                    :style="{ opacity: report.label === 'Active sketchpad report' ? '1' : '0.15' }"
                    stroke="white"
                    stroke-width="3"
                    d="M170.705+170.705L171.055+340.411C264.713+340.055+340.442+264.014+340.411+170.355L170.705+170.705Z"
                    :fill="$options.gameColors.workingMemoryColor"
                  />
                  <path
                    :style="{ opacity: report.label === 'Monitor & control report' ? '1' : '0.15' }"
                    stroke="white"
                    stroke-width="3"
                    d="M170.705+170.705L1.00001+171.055C1.3553+264.713+77.3966+340.442+171.055+340.411L170.705+170.705Z"
                    :fill="$options.gameColors.impulseControlColor"
                  />
                </g>
              </svg>
              {{ report.label }}
              <span
                v-show="openedSubReport(report.reportType)"
                class="test-white text-base"
              >&nbsp;(opened)</span>
            </a>
          </li>
        </ul>
      </div>
    </td>
    <td
      class=" table-background-color"
    >
      <div
        class="flex justify-center"
        v-if="!openedFull"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-10 h-10 opacity-60"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15"
          />
        </svg>
      </div>
      <div
        class="flex justify-center"
        v-else
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-10 h-10 cursor-pointer"
          @click="$emit('share', {candidate: {user_id: candidate.user_id, email: candidate.email}})"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15"
          />
        </svg>
      </div>
    </td>
  </tr>
  <tr
    v-else
    class="border-2 py-4 my-2"
  >
    <td class="py-6">
      <div class="flex items-center">
        <div class="flex items-center">
          <button
            @click="handleFavorite"
            class="mr-4 cursor-pointer"
          >
            <svg
              class="w-10 h-10 text-green-900 star"
              :class="{'star-solid': candidate.favorite}"
              viewBox="0 0 24 24"
              stroke="#46634F"
              stroke-width="1.4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
              />
            </svg>
          </button>
          <div class="flex flex-col mt-1 py-4">
            <span
              :class="{ 'text-xs': showCandidateInfo }"

              class="text-base"
              v-if="!showCandidateInfo"
            >Candidate {{ candidate.alt_id }}

            </span>
            <span
              v-if="showCandidateInfo && candidate.name"
              class="text-md black-cam3 text-base font-medium"
              style="width: fit-content"
              v-tippy="{arrow : true, arrowType : 'round', animation : 'fade', size: 'large', allowHTML: true, placement : 'top'}"
              :content="'Age: '+ candidate.current_age + '<br>' + 'Gender: ' + candidate.gender"
            >{{ candidate.name }}
            </span>
            <div class="flex flex-row items-center">
              <span
                v-if="showCandidateInfo && candidate.email"
                class="font-normal text-sm black-cam4 my-2"
              >{{
                candidate.email
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </td>
    <td />
    <td class="py-6 text-xs">
      <div class="flex items-center justify-center">
        {{ `${candidate.teamRole}, ${candidate.position}, ${candidate.company}, ${candidate.division}, ${candidate.department}` }}
      </div>
    </td>
    <td />
    <td />

    <td
      class="py-6 table-cell table-background-color"
      colspan="2"
    >
      <div class="flex h-full p-6 items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
          />
        </svg>
        <div class="pl-2 text-sm font-medium">
          This candidate has not completed the test
        </div>
      </div>
    </td>

    <td
      class="py-6 table-background-color"
    />
    <td
      class="text-base py-6 table-background-color"
      :class="{ 'font-extrabold': sorting.variable === 'strength' }"
    />

    <td class="py-6 table-background-color" />
    <td
      class=" table-background-color"
    />
  </tr>
</template>

<script>

import CapacityIcon from './CapacityIcon.vue'
import MatchingBox from '@/components/requirement/MatchingBox.vue'

import { attentionColor, shortTermMemoryColor, workingMemoryColor, impulseControlColor, creativityCognitiveFlexibilityColor, conceptualizationColor, strategicThinkingColor } from '../../AppReport/chartConfig'
import { mapGetters } from 'vuex'

import { Scoreboard, Charts } from '@/content/reportContent.json'

export default {
  components: {
    CapacityIcon,
    MatchingBox
  },
  props: {
    candidate: {
      type: Object,
      required: true
    },
    campaignId: {
      type: String,
      required: true
    },
    showCandidateInfo: {
      type: Boolean,
      default: false,
      required: true
    },
    sorting: {
      type: Object,
      required: true
    },
    showInfo: {
      type: Boolean,
      default: true
    },
    hasDoneReport: {
      type: Boolean,
      default: false
    }
  },
  gameColors: {
    attentionColor,
    shortTermMemoryColor,
    workingMemoryColor,
    impulseControlColor,
    creativityCognitiveFlexibilityColor,
    conceptualizationColor,
    strategicThinkingColor
  },
  data () {
    return {
      copied: false,
      showConfirmModalFull: false,
      content: Scoreboard,
      percentage: 0,
      reportTypes: [
        { index: 0, label: 'Gather & process report', reportType: 'gather_process_report' },
        { index: 1, label: 'Active sketchpad report', reportType: 'active_sketchpad_report' },
        { index: 2, label: 'Monitor & control report', reportType: 'master_control_report' },
        { index: 3, label: 'Envision report', reportType: 'execute_report' }]
    }
  },
  computed: {
    ...mapGetters({ isLoggedAdminIn: 'ADMIN/isLoggedAdminIn' }),
    parsedDate () {
      return new Date(this.candidate.dateOfBirth).toLocaleDateString('sv-SE')
    },
    openedFull () {
      if (this.candidate.stages) {
        return this.candidate.stages.includes('full_cognitive_report')
      }
      return false
    },
    openedExecutive () {
      if (this.candidate.stages) {
        return this.candidate.stages.includes('executive_summary_report')
      }
      return false
    },
    candidateIsOpened () {
      if (this.candidate.stages) {
        return this.candidate.stages.length > 0
      }
      return false
    },
    testedReports () {
      return this.reportTypes.filter(report => this.candidate && this.candidate.tested && this.candidate.tested[report.index])
    },
    hasTestedReports () {
      return this.testedReports.length > 0
    },
    hasFullReport () {
      return this.testedReports.length === 4
    }
  },
  methods: {
    async copyToClipBoard (email) {
      try {
        this.$copyText(email)

        this.copied = true
        setTimeout(() => {
          this.copied = false
        }, 1500)
      } catch (err) {
        console.log('could not copy')
      }
    },
    async handleFavorite () {
      if (this.candidate.favorite) {
        this.candidate.favorite = false
        this.$api.deleteFavorite(this.campaignId, this.candidate.user_id)
      } else {
        this.candidate.favorite = true
        this.$api.createFavorite(this.campaignId, this.candidate.user_id)
      }
    },
    getStrengthLabel (strengthKey) {
      return strengthKey ? Charts.MainMeasures[strengthKey].label : ''
    },
    openedSubReport (name) {
      if (this.candidate.stages) {
        return this.candidate.stages.includes(name)
      }
      return false
    },
    handleOpenReport () {
      if (this.candidateIsOpened || this.isLoggedAdminIn) {
        this.$router.push({ name: 'AppReport', params: { userId: this.candidate.user_id }, query: { comparisonPage: true, campaignId: this.campaignId, isTeam: true } })
      } else {
        this.$emit('openReport', { candidate: { user_id: this.candidate.user_id, alt_id: this.candidate.alt_id }, reportType: 'full_cognitive_report' })
      }
    },
    handleOpenExecutive () {
      if (this.candidateIsOpened || this.isLoggedAdminIn) {
        this.$router.push({ name: 'ExecutiveReport', params: { userId: this.candidate.user_id }, query: { campaignId: this.campaignId, isTeam: true } })
      } else {
        this.$emit('openReport', { candidate: { user_id: this.candidate.user_id, alt_id: this.candidate.alt_id }, reportType: 'executive_summary_report', label: 'Executive Summary Report' })
      }
    },
    handleOpenSubReport (report) {
      if (this.candidateIsOpened || this.isLoggedAdminIn) {
        this.$router.push({ name: 'SubReport', params: { userId: this.candidate.user_id }, query: { comparisonPage: true, campaignId: this.campaignId, reportType: report.reportType, showInfo: this.showInfo } })
      } else {
        this.$emit('openReport', { candidate: { user_id: this.candidate.user_id, alt_id: this.candidate.alt_id }, reportType: report.reportType, label: report.label })
      }
    },
    testStatusTooltip () {
      let tooltip
      const test = this.candidate.tested
      if (test) {
        if (test[0] && test[1] && test[2] && test[3]) {
          tooltip = 'Full Cognitive Profile'
        } else if (test[0]) {
          tooltip = 'Attention'
        } else if (test[1]) {
          tooltip = tooltip !== undefined ? tooltip : '' + 'Memory'
        } else if (test[2]) {
          tooltip = tooltip !== undefined ? tooltip : '' + 'Impulse control, Creativity & Flexibility'
        } else if (test[3]) {
          tooltip = tooltip !== undefined ? tooltip : '' + 'Conceptualization & Strategy'
        }
      }
      return tooltip
    },
    stateColorChange (state, testIssue) {
      if (testIssue !== null && state >= 3) {
        return 4
      } else {
        return state
      }
    }
  }
}
</script>

<style>
      .star {
        fill: transparent;
      }
      .star:hover {
        fill: #46634F;
      }
      .star-solid {
        fill: #46634F;
      }
  .table-background-color{
    background: #F3F6F9;
  }
  </style>
